import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import { round } from 'lodash';

export default class extends Controller {
  static targets = ['table'];

  static values = {
    sortColumn: Number,
  };

  connect() {
    const self = this;
    if (this.hasTableTarget) {
      const table = $(this.tableTarget).DataTable({
        ...this.defaultConfig(),
        order: [this.sortColumnValue, this.data.get('sortDir') || 'desc'],
        autoWidth: false,
        columnDefs: [{ orderable: false }],
        scrollX: true,
        scrollY: false,
        fixedColumns: true,
      });

      const columnsCount = table.rows().count();
      const averageArray = [];

      table.columns('.sum').every(function formatTable() {
        const sum = this.data().reduce((a, b) => {
          let aNumber = parseInt(a, 10);
          let bNumber = parseInt(b, 10);
          if (Number.isNaN(aNumber)) aNumber = self.parseHref(a);
          if (Number.isNaN(bNumber)) bNumber = self.parseHref(b);
          return aNumber + bNumber;
        }, 0);
        const avg = columnsCount !== 0 ? round(sum / columnsCount, 2) : 0;
        averageArray.push(avg);

        const values = this.data().map((e) => self.parseHref(e));

        const maxValue = Math.max.apply(null, values);

        this.nodes()
          .to$()
          .each((_, cell) => {
            const cellValue = parseInt(
              $(cell)
                .find('div.progress div.progress-bar div.progress-bar-text a')
                .text(),
              10,
            );
            const targetElement = $(cell).find(
              'div.progress div.progress-bar div.progress-bar-text a',
            );
            const markerElement = $(cell).find(
              'div.progress div.progress-bar-marker',
            );

            const barElement = $(cell).find('div.progress div.progress-bar');

            const classNames = [
              'churn',
              'legacy-refactor',
              'new-work',
              'help-other',
              'efficiency',
            ];
            const isSpecialMarker = classNames.some((className) =>
              markerElement.hasClass(className),
            );
            const leftValue = isSpecialMarker
              ? `${avg}%`
              : `${round((avg * 100) / maxValue, 2)}%`;
            markerElement.css('left', leftValue);

            if (cellValue >= avg) {
              targetElement.addClass('text-green');
            } else {
              targetElement.addClass('text-red');
              barElement.addClass('bg-danger');
            }
          });

        return $(this.footer()).html(`${sum}`);
      });

      $(table.table().footer())
        .find('tr:eq(1) th')
        .slice(1)
        .each((index, element) => {
          $(element).text(averageArray[index]);
        });
    }
    const filterElement = $('#developer-progress-table_filter');
    filterElement.prepend(
      `<span class="me-1 material-icons-outlined" role="button">info</span><span class="me-3">Click value for more detail</span>`,
    );
  }

  defaultConfig() {
    return {
      dom: `
        <"row mt-2 mb-3 font-small-3"<"col-sm-12 col-md-4"l><"col-sm-12 col-md-8"f>>
        <"table-responsive"tr>
        <"row mt-2 font-small-3"<"col-sm-12 col-md-4 text-muted"i><"col-sm-12 col-md-8"p>>
      `,
      drawCallback: this.drawCallback(),
    };
  }

  drawCallback() {
    Turbolinks.start();
  }

  parseHref(value) {
    const htmlObject = $(value);
    return parseInt(htmlObject.text(), 10);
  }
}
