import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import moment from 'moment';

require('select2');
require('daterangepicker');

export default class extends Controller {
  static targets = ['dev', 'dates', 'repo1', 'year', 'repo2'];

  connect() {
    const self = this;
    const current = moment();
    const start = moment().subtract(7, 'days');
    const end = current;

    const select2Config = {
      width: '100%',
      dropdownAutoWidth: true,
      selectionCssClass: 'select2-no-border',
    };

    const drpConfig = {
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoApply: true,
      maxDate: current,
      autoUpdateInput: false,
      startDate: start,
      endDate: end,
      locale: {
        format: 'MMMM DD, YYYY',
      },
      ranges: {
        Today: [current, current],
        Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 days': [moment().subtract(6, 'days'), current],
        'Last 30 days': [moment().subtract(1, 'month'), current],
      },
    };

    if (this.hasDevTarget) {
      $(this.devTarget).select2(select2Config);
      $(this.devTarget).on('select2:select', () => {
        self.fetchData();
      });
    }

    if (this.hasRepo1Target) {
      $(this.repo1Target).select2(select2Config);
      $(this.repo1Target).on('select2:select', () => {
        self.submitForm();
      });
    }

    if (this.hasRepo2Target) {
      $(this.repo2Target).select2(select2Config);
      $(this.repo2Target).on('select2:select', () => {
        self.submitForm();
      });
    }

    if (this.hasYearTarget) {
      $(this.yearTarget).select2(select2Config);
      $(this.yearTarget).on('select2:select', () => {
        self.submitForm();
      });
    }

    if (this.hasDatesTarget) {
      $(this.datesTarget)
        .daterangepicker(
          drpConfig,
          self.drpCallBack(start, end, this.datesTarget),
        )
        .on('apply.daterangepicker', (_, picker) => this.drpApply(picker));
    }

    if (this.isRemote()) {
      this.submitForm();
    }
  }

  submitForm() {
    if (this.isRemote()) {
      Rails.fire(this.element, 'submit');
    } else {
      this.element.submit();
    }
  }

  isRemote() {
    return this.element.getAttribute('data-remote');
  }

  drpApply(picker) {
    picker.element.val(
      `${picker.startDate.format(
        picker.locale.format,
      )} - ${picker.endDate.format(picker.locale.format)}`,
    );
    this.submitForm();
  }

  drpCallBack(start, end, selector) {
    $(selector).val(
      `${start.format('MMMM DD, YYYY')} - ${end.format('MMMM DD, YYYY')}`,
    );
  }

  fetchData() {
    const target1 = $(this.devTarget);
    const target2 = $(this.repo1Target);
    const target3 = $(this.repo2Target);

    const value1 = target1.val();
    const value2 = target2.val();
    const value3 = target3.val();
    $.ajax({
      url: '/developers/filter',
      type: 'GET',
      data: { dev_value: value1 },
      success: (data) => {
        this.updateCollection(target2, data.repos, value2);
        this.updateCollection(target3, data.repos, value3);

        if (data.dev) {
          window.location.href = `/developers/performance?dev=${data.dev}`;
        } else {
          console.error('dev value not available');
        }
      },
    });
  }

  updateCollection(target, newCollection, value) {
    target.empty();
    newCollection.forEach((option) => {
      target.append(`<option value=${option.id}>${option.name}</option>`);
    });
    if (value !== '0') {
      target.val('0');
    }
  }
}
