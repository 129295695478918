import { Controller } from 'stimulus';
import Turbolinks from 'turbolinks';
import { round } from 'lodash';

export default class extends Controller {
  static targets = ['table'];

  static values = {
    sortColumn: Number,
  };

  connect() {
    const self = this;
    if (this.hasTableTarget) {
      const table = $(this.tableTarget).DataTable({
        ...this.defaultConfig(),
        order: [this.sortColumnValue, this.data.get('sortDir') || 'desc'],
        autoWidth: false,
        columnDefs: [{ orderable: false }],
        scrollX: true,
        scrollY: false,
        fixedColumns: true,
      });

      const columnsCount = table.rows().count();

      table.columns('.sum').every(function formatTable() {
        const sum = this.data().reduce((a, b) => {
          let aNumber = parseInt(a, 10);
          let bNumber = parseInt(b, 10);
          if (Number.isNaN(aNumber)) aNumber = self.parseHref(a);
          if (Number.isNaN(bNumber)) bNumber = self.parseHref(b);
          return aNumber + bNumber;
        }, 0);
        const avg = columnsCount !== 0 ? round(sum / columnsCount, 2) : 0;

        this.nodes()
          .to$()
          .each((_, cell) => {
            if (parseInt($(cell).text(), 10) >= avg) {
              const targetElement =
                $(cell).children('a').length > 0
                  ? $(cell).children('a')
                  : $(cell);
              targetElement.addClass('text-green');
            } else {
              $(cell).addClass('text-blue');
            }
          });
        return $(this.footer()).html(`${sum}(${avg})`);
      });
    }
  }

  defaultConfig() {
    return {
      dom: `
        <"row mt-2 mb-3 font-small-3"<"col-sm-12 col-md-6"l><"col-sm-12 col-md-6"f>>
        <"table-responsive"tr>
        <"row mt-2 font-small-3"<"col-sm-12 col-md-4 text-muted"i><"col-sm-12 col-md-8"p>>
      `,
      drawCallback: this.drawCallback(),
    };
  }

  drawCallback() {
    Turbolinks.start();
  }

  parseHref(value) {
    const htmlObject = $(value);
    return parseInt(htmlObject.text(), 10);
  }
}
