import Highcharts from 'highcharts/highstock';
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

// eslint-disable-next-line func-names
(function (H) {
  // eslint-disable-next-line no-param-reassign, func-names
  H.seriesTypes.pie.prototype.animate = function (init) {
    const series = this;
    const { chart } = series;
    const { points } = series;
    const { animation } = series.options;
    const { startAngleRad } = series;

    // eslint-disable-next-line no-shadow
    function fanAnimate(point, startAngleRad) {
      const { graphic } = point;
      const args = point.shapeArgs;

      if (graphic && args) {
        graphic
          // Set inital animation values
          .attr({
            start: startAngleRad,
            end: startAngleRad,
            opacity: 1,
          })
          // Animate to the final position
          .animate(
            {
              start: args.start,
              end: args.end,
            },
            {
              duration: animation.duration / points.length,
            },
            () => {
              // On complete, start animating the next point
              if (points[point.index + 1]) {
                fanAnimate(points[point.index + 1], args.end);
              }
              // On the last point, fade in the data labels, then
              // apply the inner size
              if (point.index === series.points.length - 1) {
                series.dataLabelsGroup.animate(
                  {
                    opacity: 1,
                  },
                  // eslint-disable-next-line no-void
                  void 0,
                  () => {
                    // eslint-disable-next-line no-shadow
                    points.forEach((point) => {
                      // eslint-disable-next-line no-param-reassign
                      point.opacity = 1;
                    });
                    series.update(
                      {
                        enableMouseTracking: true,
                      },
                      false,
                    );
                    chart.update({
                      plotOptions: {
                        pie: {
                          innerSize: '40%',
                          borderRadius: 8,
                        },
                      },
                    });
                  },
                );
              }
            },
          );
      }
    }

    if (init) {
      // Hide points on init
      points.forEach((point) => {
        // eslint-disable-next-line no-param-reassign
        point.opacity = 0;
      });
    } else {
      fanAnimate(points[0], startAngleRad);
    }
  };
})(Highcharts);

function drawActiveProjectTimeAllocation(data, target) {
  const generateData = Object.entries(data).map(([key, value]) => ({
    name: key,
    y: value,
  }));

  const options = {
    chart: {
      type: 'pie',
    },
    title: false,
    tooltip: {
      pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>',
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        borderWidth: 2,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b><br>{point.percentage:.1f}%',
          distance: 20,
        },
      },
    },
    navigation: {
      buttonOptions: {
        enabled: false,
      },
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        enableMouseTracking: false,
        animation: {
          duration: 1000,
        },
        colorByPoint: true,
        data: generateData,
      },
    ],
  };

  Highcharts.chart(target, options);
}

function drawActiveDeveloperChart(data, customFilter, target) {
  const throughputData = [];
  const churnData = [];
  const commitData = [];
  const legacyRefactorData = [];
  const helpOtherData = [];
  data.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      commitData.push([Date.parse(key), value?.commit]);
      switch (customFilter) {
        case 0:
          throughputData.push([Date.parse(key), value?.throughput]);
          churnData.push([Date.parse(key), value?.churn]);
          break;
        case 1:
          legacyRefactorData.push([Date.parse(key), value?.legacy_refactor]);
          break;
        case 2:
          helpOtherData.push([Date.parse(key), value?.help_other]);
      }
    });
  });

  const options = {
    chart: {
      type: 'column',
    },
    title: false,
    xAxis: {
      type: 'datetime',
      labels: {
        format: '{value:%b %e}',
      },
      scrollbar: {
        enabled: true,
      },
      startOfWeek: 2,
      min: commitData.length > 0 ? commitData[0][0] : null,
      max:
        commitData.length > 0
          ? commitData.length < 15
            ? commitData[commitData.length - 1][0]
            : commitData[14][0]
          : null,
    },
    tooltip: {
      formatter() {
        const { point } = this;
        const date = Highcharts.dateFormat('%A, %b %e, %Y', this.x);
        let tooltipContent = `<b>${date}</b><br/>Commit: ${point.y}<br/>`;

        switch (customFilter) {
          case 0:
            const throughputValue = throughputData.find(
              (dt) => dt[0] === point.x,
            );
            const churnValue = churnData.find((dt) => dt[0] === point.x);
            tooltipContent += `Throughput: ${
              throughputValue ? throughputValue[1] : 'N/A'
            }<br/>Churn: ${churnValue ? churnValue[1] : 'N/A'}`;
            break;
          case 1:
            const legacyRefactorValue = legacyRefactorData.find(
              (dt) => dt[0] === point.x,
            );
            tooltipContent += `Legacy Refactor: ${
              legacyRefactorValue ? legacyRefactorValue[1] : 'N/A'
            }`;
            break;
          case 2:
            const helpOtherValue = helpOtherData.find(
              (dt) => dt[0] === point.x,
            );
            tooltipContent += `Help Other: ${
              helpOtherValue ? helpOtherValue[1] : 'N/A'
            }`;
            break;
        }
        return tooltipContent;
      },
    },
    yAxis: {
      min: 0,
      title: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series: [
      {
        name: 'Commit',
        data: commitData,
      },
    ],
  };
  Highcharts.chart(target, options);
}

export { drawActiveProjectTimeAllocation, drawActiveDeveloperChart };
