import { Controller } from 'stimulus';
import { Tooltip } from 'bootstrap';
import drawContributionGraph from '../helper/contribution-graph/index';

export default class extends Controller {
  static values = {
    data: Array,
  };

  connect() {
    this.removeTooltips();
    this.drawContributionGraph();
    this.drawTooltip();
  }

  drawContributionGraph() {
    const data = this.dataValue;
    drawContributionGraph({
      data,
      ssr: false,
      config: {
        graphMountElement: '#contribution-graph',
      },
    });
  }

  drawTooltip = () => {
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]'),
    );
    tooltipTriggerList.map(
      (tooltipTriggerEl) =>
        new Tooltip(tooltipTriggerEl, {
          trigger: 'hover',
        }),
    );
  };

  removeTooltips() {
    $('.tooltip').remove();
  }
}
