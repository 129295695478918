import { format, getDay, parseISO, addDays, differenceInDays } from 'date-fns';
import THEMES from './themes';
import utils from './utils';

// eslint-disable-next-line consistent-return
export default function drawContributionGraph({
  data,
  ssr = false,
  config: {
    graphTheme = 'standard',
    customTheme = {},
    graphMountElement = 'body',
    graphWidth = 900,
    graphHeight = 160,
  } = {},
}) {
  let allSvgs = '';
  let selectedTheme;

  if (graphTheme === 'custom') {
    selectedTheme = customTheme;
  } else if (Object.keys(THEMES).includes(graphTheme)) {
    selectedTheme = THEMES[graphTheme] ? THEMES[graphTheme] : THEMES.standard;
  } else {
    console.warn(
      "The specified graphTheme is not recognized. Defaulting to the 'standard' theme. Please ensure your chosen theme is listed in themes.js.",
    );
    selectedTheme = THEMES.standard;
  }

  // eslint-disable-next-line no-use-before-define
  const svg = drawContributionGraphForYear(data, {
    selectedTheme,
    graphMountElement,
    graphWidth,
    graphHeight,
  });
  allSvgs += svg;
  // eslint-disable-next-line no-use-before-define

  if (!ssr) {
    const mountElem = document.querySelector(graphMountElement);
    const tempContainer = document.createElement('div');
    tempContainer.innerHTML = allSvgs;
    while (tempContainer.firstChild) {
      mountElem.appendChild(tempContainer.firstChild);
    }
  } else {
    // eslint-disable-next-line consistent-return
    return allSvgs;
  }
}

const drawContributionGraphForYear = (data, config) => {
  const { graphWidth, graphHeight, selectedTheme } = config;

  let graphSvgString = '';

  const dayTextMaxWidth = 28;
  const monthTextMaxHeight = 14;

  const boxWidth = 13;
  const boxHeight = 13;
  const boxGapX = 3;
  const boxGapY = 3;
  const maxBoxesInColumn = 7;

  let offsetX = 0;
  let offsetY = 0;

  // draw days
  const daysOffsetX = offsetX;
  let daysOffsetY;

  // eslint-disable-next-line no-plusplus
  for (let y = 0; y < utils.constants.DAYS.length; y++) {
    daysOffsetY =
      (2 * y + 1) * boxHeight + 2 * (y + 1) * boxGapY + monthTextMaxHeight;

    // eslint-disable-next-line no-use-before-define
    graphSvgString += createTextNode({
      label: utils.constants.DAYS[y],
      xPos: daysOffsetX,
      yPos: daysOffsetY + 9,
    });
  }

  offsetX += dayTextMaxWidth;

  // draw months
  let monthsOffsetX = boxGapX + offsetX;
  const monthsOffsetY = 0;

  const monthsBetween = utils.monthsBetween();
  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < monthsBetween.length; x++) {
    const dateM = new Date(monthsBetween[x]);

    // Get the last day of the month by setting day to 0
    dateM.setDate(0);

    const numberOfDaysInMonth = dateM.getDate();
    const { quotient } = utils.getQuotientAndReminder(
      numberOfDaysInMonth,
      maxBoxesInColumn,
    );

    // eslint-disable-next-line no-use-before-define
    graphSvgString += createTextNode({
      label: format(monthsBetween[x], 'MMM'),
      xPos: monthsOffsetX,
      yPos: monthsOffsetY + 9,
    });

    monthsOffsetX = monthsOffsetX + quotient * (boxWidth + boxGapX) + boxGapX;
  }

  offsetY += monthTextMaxHeight;

  // draw boxes
  // eslint-disable-next-line no-use-before-define
  const populatedData = populateData(data, selectedTheme);
  const firstDay = getDay(utils.getPreviousYearDate());
  let totalColumns = Math.ceil(populatedData.length / maxBoxesInColumn);
  if (firstDay > 0) {
    totalColumns += 1;
  }

  let boxOffsetX = offsetX + boxGapX;
  let currBoxIndex = 0;

  // eslint-disable-next-line no-plusplus
  for (let x = 0; x < totalColumns; x++) {
    let boxOffsetY =
      x === 0
        ? offsetY + boxGapY + firstDay * (boxHeight + boxGapY)
        : offsetY + boxGapY;

    const loopInitializer = x === 0 ? firstDay : 0;
    const loopCondition = maxBoxesInColumn;

    // eslint-disable-next-line no-plusplus
    for (let y = loopInitializer; y < loopCondition; y++) {
      const boxData = populatedData[currBoxIndex];

      if (boxData) {
        const { done, date, color } = boxData;
        const tooltipText = done
          ? `${done} contributions on ${format(parseISO(date), 'PPPP')}`
          : `no contributions on ${format(parseISO(date), 'PPPP')}`;

        // eslint-disable-next-line no-use-before-define
        graphSvgString += createRectNode({
          xPos: boxOffsetX,
          yPos: boxOffsetY,
          fill: color,
          tooltipText,
          date,
        });
      }
      // eslint-disable-next-line no-plusplus
      currBoxIndex++;
      boxOffsetY += boxHeight + boxGapY;
    }
    boxOffsetX += boxWidth + boxGapX;
  }

  // eslint-disable-next-line no-use-before-define
  const svgString = createYearGraphNode({
    width: graphWidth,
    height: graphHeight,
    children: graphSvgString,
  });

  return svgString;
};

const populateData = (initialData, selectedTheme) => {
  const finalArr = [];

  const startDate = utils.getPreviousYearDate();
  const endDate = new Date();

  const daysBetween = differenceInDays(endDate, startDate) + 1;

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < daysBetween; i++) {
    const date = addDays(startDate, i);
    const dayIndex = i;
    const dateFormat = format(date, 'yyyy-MM-dd');

    finalArr.push({
      done: 0,
      not_done: 0,
      dayIndex,
      date: dateFormat,
      color: selectedTheme.level0,
    });
  }

  const maxValueOfDone = initialData.reduce(
    (max, obj) => Math.max(max, obj.done),
    0,
  );
  const filledEntries = initialData
    .map((d) => ({
      dayIndex: differenceInDays(parseISO(d.date), startDate),
      color: utils.getBoxColor(d.done, maxValueOfDone, selectedTheme),
      ...d,
    }))
    .sort((a, b) => a.dayIndex - b.dayIndex);

  filledEntries.forEach((entry) => {
    finalArr[entry.dayIndex] = entry;
  });

  return finalArr;
};

// svg nodes
const createTextNode = ({
  fontFamily = 'Helvetica',
  fontSize = 10,
  label,
  xPos,
  yPos,
}) => `
    <text font-family="${fontFamily}" font-size="${fontSize}" x="${xPos}" y="${yPos}">
      <tspan dy="0" x="${xPos}">${label}</tspan>
    </text>
  `;

const createRectNode = ({
  width = 20,
  height = 20,
  xPos,
  yPos,
  fill,
  stroke = '#1b1f230f',
  strokeWidth = 1,
  borderRadius = 2,
  tooltipText,
  date,
  classNames,
}) => `
  <rect
    width="${width}"
    height="${height}" 
    x="${xPos}" y="${yPos}" 
    fill="${fill}" stroke="${stroke}" stroke-width="${strokeWidth}" rx="${borderRadius}"
    ${date ? `data-date="${date}"` : ''} 
    data-bs-toggle="tooltip" data-bs-placement="top" title="${tooltipText}"
    class="${`github-contribution-graph-box-${date} ${
      classNames || ''
    }`}"></rect>
  `;

const createYearGraphNode = ({ width, height, children, id, className }) => `
  <svg 
    xmlns="http://www.w3.org/2000/svg" 
    version="1.1"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 ${width} ${height}"
    width="${width}" height="${height}"
    id="${id}"
    class="${className}"
    >${children}</svg>
  `;
