import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.renderUI();
  }

  renderUI() {
    const circularProgress = document.querySelectorAll('.circular-progress');

    Array.from(circularProgress).forEach((progressBar) => {
      const progressValue = progressBar.querySelector('.percentage');
      const innerCircle = progressBar.querySelector('.inner-circle');
      let startValue = 0;
      const endValue = Number(progressBar.getAttribute('data-percentage'));
      const speed = 50;
      const progressColor = progressBar.getAttribute('data-progress-color');
      if (endValue > 0) {
        const progress = setInterval(() => {
          startValue++;
          progressValue.textContent = `${startValue}%`;
          progressValue.style.color = `${progressColor}`;

          innerCircle.style.backgroundColor = `${progressBar.getAttribute(
            'data-inner-circle-color',
          )}`;

          const progressDegrees = Math.min(startValue * 3.6, 360);
          progressBar.style.background = `conic-gradient(${progressColor} ${progressDegrees}deg,${progressBar.getAttribute(
            'data-bg-color',
          )} 0deg)`;
          if (startValue >= endValue || startValue >= 100) {
            clearInterval(progress);
          }
        }, speed);
      }
    });
  }
}
