import { Controller } from "stimulus";
import Chart from 'chart.js/auto';

export default class extends Controller {
  static targets = [ "chart" ]

  connect() {
    if(this.hasChartTarget) {
      const ctx = $(this.chartTarget).get(0)
      const chart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
          datasets: [{
            data: $(this.chartTarget).data('last-week'),
            borderColor: "#d0d7e1",
            pointRadius: 0,
            borderWidth: 2,
            fill: false,
            label: 'Impact last week'
          }, {
            data: $(this.chartTarget).data('this-week'),
            borderColor: "#77c0b4",
            backgroundColor: "#77c0b4",
            pointRadius: 0,
            borderWidth: 2,
            fill: false,
            label: 'Impact this week'
          }]
        },
        options: {
          scales: {
            xAxes: [{
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 10,
                fontColor: "#95AAC9",
                fontSize: 13,
                autoSkip: false,
                maxRotation: 0,
              }
            }],
            yAxes: [{
              gridLines: {
                drawBorder: false
              },
              ticks: {
                fontColor: "#95AAC9",
                fontSize: 13
              }
            }]
          }
        }
      })

      chart.render();
    }
  }
}
