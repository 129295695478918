import {
  getDate,
  getDaysInMonth,
  getMonth,
  eachMonthOfInterval,
} from 'date-fns';

const utils = {
  constants: {
    DAYS: ['Mon', 'Wed', 'Fri'],
    MONTHS: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
  },
  getQuotientAndReminder(dividend, divisor) {
    const rawQuotient = dividend / divisor;
    const reminder = rawQuotient % 1;
    const quotient = rawQuotient - reminder;

    return { quotient, reminder };
  },
  // eslint-disable-next-line consistent-return
  getBoxColor(done, maxValueOfDone, selectedTheme) {
    const { quotient } = this.getQuotientAndReminder(maxValueOfDone, 5);

    if (done === 0) {
      return selectedTheme.level0;
    }
    if (done < quotient) {
      return selectedTheme.level1;
    }
    if (done >= quotient && done < 2 * quotient) {
      return selectedTheme.level2;
    }
    if (done >= 2 * quotient && done < 3 * quotient) {
      return selectedTheme.level3;
    }
    if (done >= 3 * quotient && done < 4 * quotient) {
      return selectedTheme.level4;
    }
    if (done >= 4 * quotient) {
      return selectedTheme.level5;
    }
  },
  getIndexOfDayInYear(date, year) {
    const monthIndex = getMonth(date);
    const day = getDate(date);

    let dayIndex = day;

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < monthIndex; i++) {
      const numberOfDaysInMonth = getDaysInMonth(new Date(year, i));
      dayIndex += numberOfDaysInMonth;
    }

    return dayIndex;
  },
  monthsBetween() {
    const startDate = this.getPreviousYearDate();
    const endDate = new Date();

    const monthsBetween = eachMonthOfInterval({
      start: startDate,
      end: endDate,
    });

    return monthsBetween;
  },
  getPreviousYearDate() {
    const currentDate = new Date();
    const year = currentDate.getFullYear() - 1;

    const previousYearDate = new Date(
      year,
      currentDate.getMonth(),
      currentDate.getDate(),
    );

    return previousYearDate;
  },
};

export default utils;
