import { Controller } from 'stimulus';
import moment from 'moment';
import toastr from 'toastr';
import { Modal } from 'bootstrap';

import 'select2';

export default class extends Controller {
  static targets = ['git'];

  connect() {
    const select2Config = {
      width: '100%',
      dropdownAutoWidth: true,
      selectionCssClass: 'select2-no-border',
    };

    $('.gituser').select2(select2Config);
  }

  syncRepository(e) {
    e.preventDefault();

    const obj = $(e.currentTarget);
    const repoId = obj.attr('data-repo');
    const self = this;

    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}/select`,
      type: 'POST',
      success(res) {
        const { repo, flash_text, jid } = res;
        const formattedUpdatedTime = moment(repo.updated_at).format(
          'DD/MM/YYYY, HH:mm:ss',
        );
        const timeTarget = $(`#repository-${repoId}-time`);
        timeTarget.html(formattedUpdatedTime);

        const btnsTarget = $(`#repository-${repoId}-btns`);

        if (repo.category === 'normal') {
          btnsTarget.empty().append(`
            <div class="btn btn-primary btn-sm rounded" data-action="click->repositories#syncRepository" data-repo=${repoId}>Sync </div>
            <div class="btn btn-danger btn-sm rounded" data-action="click->repositories#cancelRepository" data-repo=${repoId}>Cancel </div>
            <div class="btn btn-warning btn-sm rounded" data-action="click->repositories#showLog" data-repo=${repoId}>View log </div>
            <div class="btn btn-success btn-sm rounded" data-action="click->repositories#editSlackChannel" data-repo=${repoId}>Edit channel </div>
          `);
        } else {
          btnsTarget.empty().append(`
            <div class="btn btn-primary btn-sm rounded" data-action="click->repositories#syncRepository" data-repo=${repoId}>Sync </div>
            <div class="btn btn-danger btn-sm rounded" data-action="click->repositories#cancelRepository" data-repo=${repoId}>Cancel </div>
            <a class="btn btn-info btn-sm rounded" href="/repositories/${repoId}/edit">Edit</a>
            <div class="btn btn-danger btn-sm rounded" data-action="click->repositories#destroyRepository" data-repo=${repoId}>Delete </div>
            <div class="btn btn-warning btn-sm rounded" data-action="click->repositories#showLog" data-repo=${repoId}>View log </div>
            <div class="btn btn-success btn-sm rounded" data-action="click->repositories#editSlackChannel" data-repo=${repoId}>Edit channel </div>
          `);
        }
        $(`#repository-${repoId}-status`).empty().append(`
          <div class="progress"><div class="progress-bar bg-green" id="job-progress-${repo.id}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;">0%</div></div>
        `);

        self.queryForPercentage(jid, repoId);

        toastr.success(flash_text, 'Success', self.toastrOption());
      },
      error(error) {
        const response = JSON.parse(error.responseText);
        toastr.error(response.error, 'Error', self.toastrOption());
      },
    });
  }

  cancelRepository(e) {
    e.preventDefault();

    const self = this;
    const obj = $(e.currentTarget);
    const repoId = obj.attr('data-repo');

    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}/cancel`,
      type: 'PUT',
      success(res) {
        const { repo, flash_text } = res;
        const formattedUpdatedTime = moment(repo.updated_at).format(
          'DD/MM/YYYY, HH:mm:ss',
        );

        const timeTarget = $(`#repository-${repoId}-time`);
        timeTarget.html(formattedUpdatedTime);

        const btnsTarget = $(`#repository-${repoId}-btns`);

        if (repo.category === 'normal') {
          btnsTarget.empty().append(`
            <div class="btn btn-sm rounded" data-action="click->repositories#syncRepository" data-repo=${repoId}>Sync </div>
            <div class="btn btn-warning btn-sm rounded" data-action="click->repositories#showLog" data-repo=${repoId}>View log </div>
            <div class="btn btn-success btn-sm rounded" data-action="click->repositories#editSlackChannel" data-repo=${repoId}>Edit channel </div>
          `);
        } else {
          btnsTarget.empty().append(`
            <div class="btn btn-sm rounded" data-action="click->repositories#syncRepository" data-repo=${repoId}>Sync </div>
            <a class="btn btn-info btn-sm rounded" href="/repositories/${repoId}/edit">Edit</a>
            <div class="btn btn-danger btn-sm rounded" data-action="click->repositories#destroyRepository" data-repo=${repoId}>Delete </div>
            <div class="btn btn-warning btn-sm rounded" data-action="click->repositories#showLog" data-repo=${repoId}>View log </div>
            <div class="btn btn-success btn-sm rounded" data-action="click->repositories#editSlackChannel" data-repo=${repoId}>Edit channel </div>
          `);
        }

        $(`#repository-${repoId}-status`).empty();

        toastr.warning(flash_text, 'Warning', self.toastrOption());
      },
    });
  }

  destroyRepository(e) {
    e.preventDefault();

    const self = this;
    const obj = $(e.currentTarget);
    const repoId = obj.attr('data-repo');

    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}`,
      type: 'DELETE',
      success(res) {
        const { flash_text } = res;

        const row = obj.closest('tr');

        row.addClass('selected');

        const table = $('#repositories-table').DataTable();
        table.row('.selected').remove().draw(false);

        toastr.warning(flash_text, 'Warning', self.toastrOption());
      },
    });
  }

  toastrOption() {
    return {
      closeButton: true,
      progressBar: true,
    };
  }

  queryForPercentage(jobId, repoId) {
    const self = this;
    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}/percentage_done`,
      type: 'GET',
      data: { job_id: jobId },
      success(data) {
        const percentage = `width: ${data.percentage_done}%;`;
        $(`#job-progress-${repoId}`)
          .attr('style', percentage)
          .text(`${data.percentage_done}%`);

        if ($(`#job-progress-${repoId}`).text() != '100%') {
          setTimeout(() => self.queryForPercentage(jobId, repoId), 5000);
        }
      },
    });
  }

  showLog(e) {
    e.preventDefault();

    const obj = $(e.currentTarget);
    const repoId = obj.attr('data-repo');

    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}/log`,
      type: 'GET',
      success(res) {
        const modal = document.querySelector('#logModal');
        modal.innerHTML = res;
        const myModal = new Modal(modal);
        myModal.show();
      },
    });
  }

  closeModal(e) {
    e.preventDefault();

    const modal = document.querySelector('#logModal');
    const myModal = Modal.getInstance(modal);
    myModal.hide();
  }

  editSlackChannel(e) {
    e.preventDefault();

    const obj = $(e.currentTarget);
    const repoId = obj.attr('data-repo');

    $.ajax({
      url: `/repositories/${parseInt(repoId, 10)}/edit_slack_channel`,
      type: 'GET',
      success(res) {
        const modal = document.querySelector('#logModal');
        modal.innerHTML = res;
        const myModal = new Modal(modal);
        myModal.show();
      },
    });
  }
}
