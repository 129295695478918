import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect() {
    if (this.hasInputTarget) {
      this.inputTargets.forEach((target) => {
        $(target).select2({
          width: '100%',
          dropdownAutoWidth: true,
        });
      });
    }
  }
}
