const THEMES = {
  standard: {
    background: '#ffffff',
    text: '#000000',
    boxBorderColor: '#1b1f230f',
    level0: '#ebedf0',
    level1: '#9be9a8',
    level2: '#40c463',
    level3: '#30a14e',
    level4: '#216e39',
    level5: '#2e4532',
  },
  figma: {
    background: '#ffffff',
    text: '#000000',
    boxBorderColor: '#00000020',
    level0: '#e5e5e5',
    level1: '#F24E1E',
    level2: '#1ABCFE',
    level3: '#A259FF',
    level4: '#0ACF83',
    lelel5: '#2e4532',
  },
};

export default THEMES;
