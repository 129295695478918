import { Controller } from 'stimulus';

import Highcharts from 'highcharts/highstock';

import {
  drawPullRequestProjectsChart,
  drawPullRequestDeveloperChart,
} from '../helper/sub-dashboards-chart/pull_request';

import {
  drawEfficiencyProjectsChart,
  drawEfficiencyDeveloperChart,
} from '../helper/sub-dashboards-chart/efficiency';

import {
  drawActiveDeveloperChart,
  drawActiveProjectTimeAllocation,
} from '../helper/sub-dashboards-chart/active_days';

import {
  drawImpactChart,
  drawCodePercentsChart,
} from '../helper/sub-dashboards-chart/impact';

// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static targets = [
    'efficiency',
    'throughput',
    'active',
    'pullRequest',
    'impact',
  ];

  static values = {
    data: Object,
    repoFilter: String,
    devFilter: String,
    projectsData: Object,
    impactData: Array,
  };

  connect() {
    this.renderUI();
  }

  renderUI() {
    if (this.hasThroughputTarget) {
      this.renderThroughputProjectsChart(this.projectsDataValue);
      this.renderThroughputDeveloperChart(this.dataValue.chart_data);
    }

    if (this.hasEfficiencyTarget) {
      drawEfficiencyProjectsChart(
        this.projectsDataValue,
        this.devFilterValue,
        'chart-projects',
      );
      drawEfficiencyDeveloperChart(
        this.dataValue.chart_data,
        'chart-developer',
      );
    }

    if (this.hasPullRequestTarget) {
      drawPullRequestProjectsChart(
        this.dataValue.chart_projects,
        this.devFilterValue,
        'chart-projects',
      );
      drawPullRequestDeveloperChart(
        this.dataValue.chart_developer,
        'chart-developer',
      );
    }

    if (this.hasActiveTarget) {
      drawActiveProjectTimeAllocation(
        this.dataValue.chart_project_time_allocation,
        'chart-project-time-allocation',
      );
      drawActiveDeveloperChart(
        this.dataValue.chart_developer,
        parseInt(this.dataValue.custom_filter, 10),
        'chart-developer',
      );
    }

    if (this.hasImpactTarget) {
      drawImpactChart(this.impactDataValue, 'chart-impact');
      drawCodePercentsChart(this.impactDataValue, 'chart-code-percents');
    }
  }

  renderThroughputProjectsChart(data) {
    const keysArray = Object.keys(data);
    const valueToFind = this.devFilterValue;
    const series = Object.entries(data).map((entry) => ({
      y: entry[1],
      color: entry[0] === valueToFind ? 'red' : 'green',
    }));

    const options = {
      chart: {
        type: 'column',
      },
      title: false,
      xAxis: {
        categories: keysArray,
        title: {
          text: null,
        },
        min: 0,
        max: keysArray.length < 15 ? keysArray.length - 1 : 14,
        scrollbar: {
          enabled: true,
        },
        tickLength: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Throughput',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Throughput',
          data: series,
        },
      ],
    };

    Highcharts.chart('chart-projects', options);
  }

  renderThroughputDeveloperChart(data) {
    const options = {
      chart: {
        type: 'line',
      },
      title: false,
      xAxis: {
        type: 'datetime',
      },
      plotOptions: {
        line: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      legend: {
        align: 'center',
      },
      credits: {
        enabled: false,
      },
      series: this.generateChartSeries(data),
    };

    Highcharts.chart('chart-developer', options);
  }

  generateChartSeries(data) {
    const throughputData = [];
    const churnData = [];
    const efficiencyData = [];

    data.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        throughputData.push([Date.parse(key), value?.throughput]);
        churnData.push([Date.parse(key), value?.churn]);
        if (value?.efficiency !== undefined)
          efficiencyData.push([Date.parse(key), value?.efficiency]);
      });
    });

    const baseRes = [
      { name: 'Throughput', data: throughputData },
      { name: 'Churn', data: churnData },
    ];

    return efficiencyData.length > 0
      ? [...baseRes, { name: 'Efficiency', data: efficiencyData }]
      : baseRes;
  }
}
