import Highcharts from 'highcharts/highstock';
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

function drawEfficiencyProjectsChart(data, devFilter, target) {
  const keysArray = Object.keys(data);
  const valueToFind = devFilter;
  const series = Object.entries(data).map((entry) => ({
    y: entry[1],
    color: entry[0] === valueToFind ? 'red' : 'green',
  }));

  const options = {
    chart: {
      type: 'column',
    },
    title: false,
    tooltip: {
      valueSuffix: ' %',
    },
    xAxis: {
      categories: keysArray,
      title: {
        text: null,
      },
      min: 0,
      max: keysArray.length < 15 ? keysArray.length - 1 : 14,
      scrollbar: {
        enabled: true,
      },
      tickLength: 0,
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'Efficiency',
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: [
      {
        name: 'Efficiency',
        data: series,
      },
    ],
  };

  Highcharts.chart(target, options);
}

const generateChartSeries = (data) => {
  const throughputData = [];
  const churnData = [];
  const efficiencyData = [];
  const keysArray = [];

  data.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      keysArray.push(Date.parse(key));
      throughputData.push([Date.parse(key), value?.throughput]);
      churnData.push([Date.parse(key), value?.churn]);
      efficiencyData.push([Date.parse(key), value?.efficiency]);
    });
  });

  return {
    categories: keysArray,
    series: [
      { name: 'Throughput', data: throughputData, visible: false },
      { name: 'Churn', data: churnData, visible: false },
      { name: 'Efficiency', data: efficiencyData },
    ],
  };
};

function drawEfficiencyDeveloperChart(data, target) {
  const { categories, series } = generateChartSeries(data);
  const chart = Highcharts.chart(target, {
    chart: {
      type: 'line',
    },
    title: false,
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      min: 0,
      max: 100,
      title: {
        text: 'Efficiency',
      },
    },
    tooltip: {
      formatter() {
        const index = $.inArray(this.x, categories);
        let tooltip = `<b>${Highcharts.dateFormat(
          '%A, %b %e, %Y',
          this.x,
        )}</b>`;

        tooltip += `<br/>${chart.series[0].name}: ${series[0].data[index][1]}`;
        tooltip += `<br/>${chart.series[1].name}: ${series[1].data[index][1]}`;

        return tooltip;
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    series,
  });
}

export { drawEfficiencyProjectsChart, drawEfficiencyDeveloperChart };
