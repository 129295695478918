import Highcharts from 'highcharts/highstock';
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

function drawPullRequestProjectsChart(data, devFilter, target) {
  const keysArray = Object.keys(data);
  const seriesNames = [
    'PRs',
    'PRs opened',
    'PRs merged',
    'PRs closed',
    'PRs merged without review',
    'PRs comment addressed',
    'PRs review addressed',
  ];

  const series = seriesNames.map((name, index) => {
    const seriesData = Object.entries(data).map(
      (entry) => Object.values(entry[1])[index],
    );

    return {
      name,
      data: seriesData,
    };
  });

  const options = {
    chart: {
      type: 'column',
    },
    title: false,
    xAxis: {
      categories: keysArray,
      title: {
        text: null,
      },
      min: 0,
      max: keysArray.length < 15 ? keysArray.length - 1 : 14,
      scrollbar: {
        enabled: true,
      },
      tickLength: 0,
    },
    yAxis: {
      min: 0,
      title: {
        text: null,
      },
      stackLabels: {
        enabled: true,
        style: {
          fontWeight: 'bold',
          color:
            (Highcharts.defaultOptions.title.style &&
              Highcharts.defaultOptions.title.style.color) ||
            'gray',
        },
      },
    },
    tooltip: {
      headerFormat: '<b>{point.x}</b><br/>',
      pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}',
    },
    legend: {
      align: 'right',
      verticalAlign: 'top',
      layout: 'vertical',
      x: 0,
      y: 50,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor || 'white',
      borderColor: '#CCC',
      borderWidth: 1,
      shadow: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: true,
        },
      },
    },
    series,
  };

  Highcharts.chart(target, options);
}

function drawPullRequestDeveloperChart(data, target) {
  const prsArray = [];
  const prsMergedArray = [];

  data.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      prsArray.push([Date.parse(key), value?.prs]);
      prsMergedArray.push([Date.parse(key), value?.prs_merged]);
    });
  });

  const series = [
    { name: 'PRs created', data: prsArray },
    { name: 'PRs merged', data: prsMergedArray },
  ];

  const options = {
    chart: {
      type: 'area',
    },
    title: false,
    xAxis: {
      type: 'datetime',
    },
    yAxis: {
      title: {
        text: null,
      },
    },
    tooltip: {
      shared: true,
      headerFormat:
        '<span style="font-size:12px"><b>{point.key}</b></span><br>',
    },
    plotOptions: {
      area: {
        stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          lineWidth: 1,
          lineColor: '#666666',
        },
      },
    },
    legend: {
      align: 'center',
    },
    credits: {
      enabled: false,
    },
    series,
  };

  Highcharts.chart(target, options);
}

export { drawPullRequestProjectsChart, drawPullRequestDeveloperChart };
