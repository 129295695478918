import { Controller } from "stimulus"
import Rails from "@rails/ujs"
import moment from "moment";

require("select2")
require("daterangepicker")

export default class extends Controller {
  static targets = [ "dev", "dev2", "repo", "repo2", "dates", "dates2" ]

  connect() {
    const self = this
    const current = moment();
    const start = moment().subtract(7, 'days');
    const end = current;

    const select2Config = {
      width: "100%",
      dropdownAutoWidth: true,
      selectionCssClass: 'select2-no-border'
    }
    const drpConfig = {
      showDropdowns: true,
      alwaysShowCalendars: true,
      autoApply: true,
      maxDate: current,
      autoUpdateInput: false,
      startDate: start,
      endDate: end,
      locale: {
        format: "MMMM DD, YYYY",
      },
      ranges: {
        'Today': [current, current],
        'Yesterday': [moment().subtract(1, "days"), moment().subtract(1, "days")],
        'Last 7 days': [moment().subtract(6, "days"), current],
        'Last 30 days': [moment().subtract(1, "month"), current]
      }
    }

    if(this.hasDevTarget) {
      $(this.devTarget).select2(select2Config)
      $(this.devTarget).on('select2:select', () => { self.submitForm() })
    }

    if(this.hasRepoTarget) {
      $(this.repoTarget).select2(select2Config)
      $(this.repoTarget).on('select2:select', () => { self.submitForm() })
    }

    if(this.hasDatesTarget) {
      $(this.datesTarget).daterangepicker(drpConfig, self.drpCallBack(start, end, this.datesTarget)).on('apply.daterangepicker', (_, picker) => this.drpApply(picker))
    }

    if(this.hasDev2Target && this.hasDates2Target) {
      $(this.dev2Target).select2(select2Config)
      $(this.dates2Target).daterangepicker(drpConfig, self.drpCallBack(start, end, this.dates2Target)).on('apply.daterangepicker', (_, picker) => this.drpApply(picker))

      $(this.dev2Target).on('select2:select', () => { self.submitForm() })

      if (this.isDeveloper() && this.hasRepo2Target) {
        $(this.repo2Target).select2(select2Config)
        $(this.repo2Target).on('select2:select', () => { self.submitForm() })
      }
    }

    if (this.isRemote()) {
      this.submitForm()
    }
  }

  submitForm() {
    if (this.isRemote()) {
      Rails.fire(this.element, "submit")
    } else {
      this.element.submit()
    }
  }

  isDeveloper() {
    return this.element.getAttribute('data-developer');
  }

  isRemote() {
    return this.element.getAttribute("data-remote")
  }

  drpApply(picker) {
    picker.element.val(`${picker.startDate.format(picker.locale.format)} - ${picker.endDate.format(picker.locale.format)}`);
    this.submitForm()
  }

  drpCallBack(start, end, selector) {
    $(selector).val(`${start.format("MMMM DD, YYYY")} - ${end.format("MMMM DD, YYYY")}`);
  }
}
