import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    const elementIds = [
      'developer-summary-path',
      'developer-progress-path',
      'developer-activities-path',
      'developer-performance-path',
    ];

    const searchParams = new URLSearchParams(window.location.search);

    const devParam = searchParams.get('dev');

    if (!devParam) return;

    elementIds.forEach((elementId) => {
      const element = document.getElementById(elementId);
      if (!element) {
        console.error(`Could not find element with ID '${elementId}'`);
        return;
      }

      const url = new URL(element.href);
      const searchElement = url.searchParams;
      searchElement.set('dev', devParam);

      element.href = url.toString();
    });
  }
}
