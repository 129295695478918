import { Controller } from 'stimulus';

import Highcharts from 'highcharts/highstock';
// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

export default class extends Controller {
  static values = {
    data: Object,
    data2: Object,
    repoFilter: String,
    devFilter: String,
  };

  connect() {
    this.renderUI();
  }

  renderUI() {
    this.renderProjectsChart(this.dataValue.chart_projects_data);
    this.renderCodeChangeChart(this.dataValue.chart_code_change_data);
    this.renderDevelopersChart(this.dataValue.chart_developers_data);
    this.renderDeveloperChart(this.data2Value.chart_developer_data);
  }

  renderProjectsChart(data) {
    const keysArray = Object.keys(data);
    const valueToFind = this.repoFilterValue;
    const series = Object.entries(data).map((entry) => ({
      y: entry[1],
      color: entry[0] === valueToFind ? 'red' : 'green',
    }));

    const options = {
      chart: {
        type: 'column',
      },
      title: false,
      xAxis: {
        categories: keysArray,
        title: {
          text: null,
        },
        min: 0,
        max: keysArray.length < 15 ? keysArray.length - 1 : 14,
        scrollbar: {
          enabled: true,
        },
        tickLength: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Code change',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Code change',
          data: series,
        },
      ],
    };

    Highcharts.chart('chart-projects', options);
  }

  renderCodeChangeChart(data) {
    const options = {
      chart: {
        type: 'area',
      },
      title: false,
      xAxis: {
        type: 'datetime',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      legend: {
        align: 'center',
      },
      series: this.generateChartSeries(data),
      credits: {
        enabled: false,
      },
    };

    Highcharts.chart('chart-code-change', options);
  }

  generateChartSeries(data) {
    const throughputData = [];
    const additionsData = [];
    const deletionsData = [];

    data.forEach((item) => {
      Object.entries(item).forEach(([key, value]) => {
        throughputData.push([Date.parse(key), value.throughput]);
        additionsData.push([Date.parse(key), value.additions]);
        deletionsData.push([Date.parse(key), value.deletions]);
      });
    });

    return [
      { name: 'Lines modified', data: throughputData },
      { name: 'Lines added', data: additionsData },
      { name: 'Lines deleted', data: deletionsData },
    ];
  }

  renderDeveloperChart(data) {
    const options = {
      chart: {
        type: 'area',
      },
      title: false,
      xAxis: {
        type: 'datetime',
      },
      plotOptions: {
        area: {
          stacking: 'normal',
          lineColor: '#666666',
          lineWidth: 1,
          marker: {
            lineWidth: 1,
            lineColor: '#666666',
          },
        },
      },
      legend: {
        align: 'center',
      },
      series: this.generateChartSeries(data),
      credits: {
        enabled: false,
      },
    };

    Highcharts.chart('chart-developer', options);
  }

  renderDevelopersChart(data) {
    const keysArray = Object.keys(data);
    const valueToFind = this.devFilterValue;
    const series = Object.entries(data).map((entry) => ({
      y: entry[1],
      color: entry[0] === valueToFind ? 'red' : 'green',
    }));

    const options = {
      chart: {
        type: 'column',
      },
      title: false,
      xAxis: {
        categories: keysArray,
        min: 0,
        max: keysArray.length < 15 ? keysArray.length - 1 : 14,
        scrollbar: {
          enabled: true,
        },
        tickLength: 0,
      },
      yAxis: {
        min: 0,
        title: {
          text: 'Code change',
        },
      },
      legend: {
        enabled: false,
      },
      credits: {
        enabled: false,
      },
      plotOptions: {
        column: {
          dataLabels: {
            enabled: true,
          },
        },
      },
      series: [
        {
          name: 'Code change',
          data: series,
        },
      ],
    };
    Highcharts.chart('chart-developers', options);
  }
}
