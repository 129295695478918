import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import * as ActiveStorage from '@rails/activestorage';
import '@/channels';

import '@tabler/core/dist/js/tabler.min';
import '@tabler/core/dist/css/tabler.min.css';
import '@/controllers/application';
import '@/scss/application/index.scss';
import LocalTime from 'local-time';

Rails.start();
Turbolinks.start();
ActiveStorage.start();
require.context('@/images', true);
LocalTime.start()

// libs
require('datatables.net-bs4')(window, $)
require('datatables.net-fixedcolumns-bs4')(window, $)
