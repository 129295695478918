import Highcharts from 'highcharts/highstock';
import { format, parseISO } from 'date-fns';

// Load module after Highcharts is loaded
require('highcharts/modules/exporting')(Highcharts);
require('highcharts/modules/export-data')(Highcharts);
require('highcharts/modules/accessibility')(Highcharts);

const formatDate = (inputDate) => {
  const dateObject = parseISO(inputDate);
  const formattedDate = format(dateObject, 'MMM d');
  return formattedDate;
};

function prepareData(data) {
  const keysArray = [];
  const values = {
    impactArray: [],
    efficiencyArray: [],
    newWorkArray: [],
    legacyRefactorArray: [],
    helpOtherArray: [],
    churnArray: [],
  };

  data.forEach((item) => {
    Object.entries(item).forEach(([key, value]) => {
      keysArray.push(formatDate(key));
      values.impactArray.push(value?.impact);
      values.efficiencyArray.push(value?.efficiency);
      values.newWorkArray.push(value?.new_work);
      values.legacyRefactorArray.push(value?.legacy_refactor);
      values.helpOtherArray.push(value?.help_other);
      values.churnArray.push(value?.churn);
    });
  });

  return { keysArray, values };
}

function drawChart(target, chartOptions) {
  Highcharts.chart(target, chartOptions);
}

function drawImpactChart(data, target) {
  const { keysArray, values } = prepareData(data);

  drawChart(target, {
    title: false,
    xAxis: [
      {
        crosshair: true,
        categories: keysArray,
      },
    ],
    yAxis: [
      {
        labels: {
          style: { color: Highcharts.getOptions().colors[1] },
        },
        title: {
          text: 'Impact',
          style: { color: Highcharts.getOptions().colors[1] },
        },
      },
      {
        title: {
          text: 'Efficiency',
          style: { color: Highcharts.getOptions().colors[0] },
        },
        labels: {
          format: '{value} %',
          style: { color: Highcharts.getOptions().colors[0] },
        },
        opposite: true,
      },
    ],
    tooltip: { shared: true },
    legend: {
      align: 'left',
      x: 80,
      verticalAlign: 'top',
      y: 0,
      floating: true,
      backgroundColor:
        Highcharts.defaultOptions.legend.backgroundColor ||
        'rgba(255,255,255,0.25)',
    },
    credits: { enabled: false },
    series: [
      {
        name: 'Impact',
        type: 'column',
        data: values.impactArray,
      },
      {
        name: 'Efficiency',
        type: 'spline',
        yAxis: 1,
        data: values.efficiencyArray,
        tooltip: { valueSuffix: '%' },
      },
    ],
  });
}

function drawCodePercentsChart(data, target) {
  const { keysArray, values } = prepareData(data);

  drawChart(target, {
    chart: { type: 'area' },
    title: false,
    xAxis: { categories: keysArray },
    yAxis: { title: false },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: 'circle',
          radius: 2,
          states: { hover: { enabled: true } },
        },
      },
    },
    credits: { enabled: false },
    series: [
      {
        name: 'New Work',
        data: values.newWorkArray,
      },
      {
        name: 'Legacy Refactor',
        data: values.legacyRefactorArray,
      },
      {
        name: 'Help Other',
        data: values.helpOtherArray,
      },
      {
        name: 'Churn',
        data: values.churnArray,
      },
    ],
  });
}

export { drawImpactChart, drawCodePercentsChart };
